.navbar {
    background-color: var(--supporting-color) !important;
    color: var(--bg-color);
}

.header-logo {
    width: 200px;
}

.menu-link {
    color: var(--bg-color) !important;
    font-weight: 700;
}

.menu-link:hover {
    background-color: var(--bg-color);
    color: var(--supporting-color) !important;
}

.navbar-nav {
    gap: 1.5rem;
}