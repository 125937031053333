.page-content {
    position: relative;
    z-index: 10;
    border-bottom-left-radius: 4rem !important;
    border-bottom-right-radius: 4rem !important;
}

footer {
    position: sticky;
    z-index: -1;
    height: 600px;
    background-color: #FFC947;
    bottom: 0;
    margin-top: -100px;
}

.footer-logo {
    width: 800px;
}

.cta-div {
    /* background-image: url(../images/CTA.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 3rem;
    position: relative; */
    border-radius: 3rem;
    background: var(--white-color);
    box-shadow: 10px 10px 30px #ffafaf40;
}

/* .cta-content::after {
    content: "";
    background-color: #ECEEFE;
    opacity: .6;
    filter: blur(2px);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 2rem;
} */

.cta-content {
    position: relative;
    color: black !important;
}

.cta-title {
    color: var(--bg-color);
    font-size: 4rem;
}

.fa-phone, .fa-envelope {
    font-size: 1.8rem !important;
}

.cta-text, .cta-form {
    position: relative;
    z-index: 9;
}

.form-control, .form-control::placeholder, .form-control:focus {
    background: transparent;
    border-color: var(--primary-color);
    color: black;
}

.cta-form .custom-btn {
    background-color: var(--primary-color);
    color: var(--white-color) !important;
}

.cta-form .custom-btn:hover {
    background-color: var(--supporting-color) !important;
    color: var(--black-color) !important;
}

/* .form-submit-btn {
    background: var(--supporting-color);
} */

@media screen and (max-width: 475px) {
    .footer-logo {
        width: 400px
    }
}