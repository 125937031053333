/* Banner  Section End */

.homepage_banner {
  background-color: var(--supporting-color);
  /* height: 90vh; */
}

.banner-title {
  color: var(--bg-color);
  font-size: 4.5rem;
}

.banner-btn {
  border-color: var(--bg-color);
  color: var(--bg-color);
  border-radius: 2rem;
}

.banner-btn i {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.banner-thumbnail-image img {
  width: 1005;
  height: 100px;
  border-radius: 2rem;
  object-fit: cover;
}

.banner-image img {
  width: 1100px;
  height: 500px;
  border-top-right-radius: 5rem;
  border-top-left-radius: 5rem;
}

.banner-btn:hover {
  background-color: var(--primary-color);
  border-color: var(--white-color);
}

/* Banner Section End */

/* Tagline Section */

.brand-tagline {
  font-family: var(--league-spartan);
  color: var(--supporting-color);
  /* font-weight: var(--section-title-weight); */
}

.brand-tagline h2 {
  font-size: var(--section-title);
  vertical-align: middle;
}

.tagline-circle-star {
  width: 50px;
  height: 50px;
  fill: var(--supporting-color);
  animation: rotateStar 5s infinite linear;
}

@keyframes rotateStar {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Tagline Section End */

/* Who We Are Section Start */

.who-we-are-image {
  width: -webkit-fill-available;
}

/* Who We Are Section End */

/* Moto Section */

.homepage_moto {
  background-color: var(--bg-color);
}

/* .moto {
    height: 250px;
    background-color: #1c1c1c;
    border-radius: 1.5rem;
} */

.moto {
  height: 250px;
  background-color: var(--primary-color);
  border-radius: 1.5rem;
}

.moto-heading {
  color: var(--supporting-color);
  font-weight: bold;
}

@media screen and (max-width: 500px) {
  .moto {
    height: 175px;
  }
}

/* Moto Section End */

/* Service Section */

.homepage_services {
  background-color: var(--bg-color);
  color: var(--secondary-color);
}

.service {
  border: 2px solid var(--primary-color);
  border-radius: 1.2rem;
  background-color: #1c1c1c;
}

.service-icons {
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--supporting-color)
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  min-height: 40px;
}

.service-description {
  display: -webkit-inline-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.service-heading {
  font-size: 4rem;
  font-weight: bold;
  color: #1c1c1c; /* Default color */
  transition: color 0.3s ease-in-out;
}

.headin h3 {
  color: var(--white-color) !important;
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .service-heading {
    font-size: 2rem;
  }
}

/* Service Section End */

/* How It Works Start */

.work {
  background: var(--primary-color);
  color: white;
  overflow: hidden;
}

.work-content:nth-child(3),
.work-content:last-child() {
  flex-direction: row-reverse !important;
}

.number-div {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.number {
  font-size: 10rem;
  color: var(--supporting-color);
}

/* How It Works End */

/* Blog Section */

.blogs-content,
.who-we-are-content {
  color: var(--secondary-color);
}

.blogs-listing {
  display: flex;
}

.blog-image {
  height: 300px;
  width: 100%;
  border-radius: 2rem;
}

.blog-title,
.who-we-are-title {
  color: var(--supporting-color);
}

.blog-description {
  color: var(--secondary-color);
}

/* Blog Section End */

/* About Us Start */

.box {
  border: 1px solid var(--primary-color);
  border-radius: 1rem;
  height: 350px;
  position: relative;
}

.box video {
  border-radius: 1rem;
  position: relative;
  width: 100%;
  height: 100%;
}

.box-content {
  position: absolute;
  z-index: 1;
  bottom: 0;
}

.box-content * {
  color: var(--white-color) !important;
}

/* About Us End */

/* Client Logos Start */

.client-logos {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
}

.logos {
  display: inline-block; /* Use flex for better layout */
  animation: logoSlide 15s infinite linear;
}

.logos:hover {
  animation-play-state: paused; /* Pause on hover */
}

.logos img {
  width: 100px;
  margin: 0 2rem;
}

.logos img:nth-child(3), .logos img:nth-child(8) {
  width: 175px;
}

.logos img:nth-child(4), .logos img:nth-child(9) {
  width: 140px;
}

.logos img:nth-child(5), .logos img:nth-child(10) {
  width: 175px;
}

@keyframes logoSlide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%); /* Match this to half the width of the repeated content */
  }
}


/* Client Logos End */

/* Media Queries */

@media screen and (max-width: 475px) {
  .banner-title {
    font-size: 5rem;
  }
  .banner-image img {
    height: 300px;
    object-fit: cover;
  }
}

@media screen and (max-width: 420px) {
  .banner-title {
    font-size: 4rem;
  }
}

/* Media Queries End */
